import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";
import Login from "./components/Login";
import WalnutDemoLibrary from "./components/WalnutDemoLibrary";
import IndustryPage from "./components/IndustryPage";

const industriesData = {
  retail: {
    title: "Retail",
    icon: "bag",
    pages: [
      {
        title: "Retail Website",
        content: "shop.quinnandmurray.com",
        externalLink: "https://shop.quinnandmurray.com/",
      },
      {
        title: "Retail Sub - Quick Links Team",
        content: "retail.quantummetric.com",
        externalLink: "https://retail.quantummetric.com/#/dashboard/d692dec4-de4b-419a-9d2b-fd9250fbf327?globalFilter=45d0aaed25c238cf&teamID=26d54ebd-9378-11ed-a1fd-42010a800137&ts=last_24_hours"
      }
    ],
  },
  airline: {
    title: "Airline",
    icon: "airplane",
    pages: [
      {
        title: "Travel Website",
        content: "travel.quinnandmurray.com",
        externalLink: "https://travel.quinnandmurray.com/",
      },
      {
        title: "Travel Sub - Flights Quick Links Team",
        content: "travel.quantummetric.com",
        externalLink: "https://travel.quantummetric.com/#/dashboard/09486ff4-783f-4f43-9d2c-426c3ca9ffff?dashboardUseGlobal=true&teamID=0f5b7bd1-a353-4755-9704-9d5165bbe451&ts=last_24_hours"
      }
    ],
  },
  hotel: {
    title: "Hotel",
    icon: "suitcase",
    pages: [
      {
        title: "Travel Website",
        content: "travel.quinnandmurray.com",
        externalLink: "https://travel.quinnandmurray.com/",
      },
      {
        title: "Travel Sub - Hotels Quick Links Team",
        content: "travel.quantummetric.com",
        externalLink: "https://travel.quantummetric.com/#/dashboard/728e96e9-5807-4a37-9f64-156f300230ad?teamID=61952508-2044-4e8a-89c3-c1fa0d556d5f&ts=last_24_hours"
      },
    ],
  },
  consumerBanking: {
    title: "Consumer Banking",
    icon: "bank",
    pages: [
      {
        title: "Financial Website",
        content: "financial.quinnandmurray.com",
        externalLink: "https://financial.quinnandmurray.com/",
      },
      {
        title: "Financial Sub - Banking Quick Links Team",
        content: "financial.quantummetric.com",
        externalLink: "https://financial.quantummetric.com/#/dashboard/8d8e1e79-a45c-4d2f-8d41-da185dd33145?dashboardUseGlobal=true&teamID=56d9f9a8-53e8-4bc0-a75a-7bdb132f4e89&ts=last_24_hours"
      },
    ],
  },
  insurance: {
    title: "Insurance",
    icon: "cash-coin",
    pages: [
      {
        title: "Financial Website",
        content: "financial.quinnandmurray.com",
        externalLink: "https://financial.quinnandmurray.com/",
      },
      {
        title: "Financial Sub - Insurance Quick Links Team",
        content: "financial.quantummetric.com",
        externalLink: "https://financial.quantummetric.com/#/dashboard/546b8b87-164b-45ef-9ec8-59916ba21805?teamID=e4b17935-974f-42de-af82-f6686153c8dd&ts=last_24_hours"
      }
    ],
  },
  telco: {
    title: "Telco",
    icon: "reception-4",
    pages: [
      {
        title: "Telco Website",
        content: "telco.quinnandmurray.com",
        externalLink: "https://telco.quinnandmurray.com/",
      },
      {
        title: "Telco Sub - Quick Links Team",
        content: "telco.quantummetric.com",
        externalLink: "https://telco.quantummetric.com/#/dashboard/06368b5c-753e-455f-b852-afcc3feb85c3?dashboardUseGlobal=true&teamID=e296272e-dfd3-11ee-8d62-42010a800025&ts=last_24_hours"
      }
    ],
  },
  health: {
    title: "Healthcare",
    icon: "heart-pulse",
    status: "In Progress",
    pages: [
      {
        title: "Healthcare Website",
        content: "health.quinnandmurray.com",
        externalLink: "https://health.quinnandmurray.com/",
      },
      {
        title: "Healthcare Sub - Quick Links Team",
        content: "health.quantummetric.com",
        externalLink: "https://health.quantummetric.com/"
      }
    ],
  },
  gaming: {
    title: "Gaming",
    icon: "controller",
    status: "In Progress",
    pages: [
      {
        title: "Gaming Website",
        content: "gaming.quinnandmurray.com",
        externalLink: "https://gaming.quinnandmurray.com/",
      },
      {
        title: "Gaming Sub - Quick Links Team",
        content: "gaming.quantummetric.com",
        externalLink: "https://gaming.quantummetric.com/"
      }
    ],
  },
  other: {
    title: "Cross-Industry (Other)",
    icon: "lightning",
    status: "Coming Soon",
    pages: [
      {
        title: "Use Preferred Industry Website",
        content: "<industry>.quinnandmurray.com",
      },
      {
        title: "Cross-Industry Team",
        content: "financial.quantummetric.com",
        externalLink: "https://financial.quantummetric.com/"
      }
    ],
  }
};

const Home = () => (
  <Container className="mt-5">
    <Row className="justify-content-center">
      {Object.keys(industriesData).map((industryKey) => {
        const industry = industriesData[industryKey];
        return (
<Col md={6} lg={4} key={industryKey}>
  <div className="industry-card p-4">
    {industry.status && (
      <div className={`corner-label ${industry.status === 'Coming Soon' ? 'coming-soon' : 'in-progress'}`}>
        {industry.status}
      </div>
    )}
    <div className="d-flex justify-content-between align-items-center mb-4">
      <h2 className="industry-title mb-0">{industry.title}</h2>
      {industry.icon && <i className={`bi bi-${industry.icon} industry-icon fs-2`}></i>}
    </div>
    {industry.pages.map((page, index) => (
      <a 
        href={page.externalLink}
        className="page-link d-block text-decoration-none text-dark"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <h5 className="mb-1">{page.title}</h5>
        <small className="text-muted">{page.content}</small>
      </a>
    ))}
  </div>
</Col>
        );
      })}
    </Row>
  </Container>
);

const WalnutDemoLibraryRoute = () => (
  <Container className="mt-4">
    <WalnutDemoLibrary />
  </Container>
);

const App = () => {
  const [user, setUser] = useState("");

  return (
    <Router>
      <div>
        {!!user ? (
          <div>
<Navbar bg="white" variant="light" className="shadow-sm">
  <Container>
    <Navbar.Brand style={{ color: "#E6005C" }} as={Link} to="/">
      <img
        src="/qm_brandmark_pink.ico"
        alt="QM logo"
        style={{ width: "30px", height: "auto", marginRight: "10px" }}
      />
      Demo Landing Page
    </Navbar.Brand>
    <Nav>
      <Nav.Link 
        as={Link} 
        to="/walnut-demo-library"
        style={{ 
          color: "#333",
          fontWeight: "500",
          padding: "0.5rem 1rem"
        }}
      >
        View Walnut Demo Library
      </Nav.Link>
    </Nav>
  </Container>
</Navbar>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/walnut-demo-library" element={<WalnutDemoLibraryRoute />} />
            </Routes>
          </div>
        ) : (
          <Login setUser={setUser} />
        )}
      </div>
    </Router>
  );
};

export default App;