import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IndustryPage from "./IndustryPage";

const demoContent = [
  {
    title: "QM Platform / Vision Overview",
    content: "Introduction to the QM Platform that outlines what you can expect to gain from partnering with us - A uniquely enterprise solution designed to meet every team's needs as you empower them to take ownership over the digital experience like never before.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=72b8597f-a9f2-49d7-b2e0-c50c661e4a1b&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "display"
  },
  {
    title: "Enterprise Privacy & Security Overview",
    content: "Quantum Metric has the most robust & secure experience analytics solution on the market. Our approach to client-side data classification and treatment is unique in its flexibility and granularity, and our patented approach to capturing iOS and Android experiences allows the same approach to be applied consistently across applications.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=203a30a5-b316-4f2d-bb49-305dcd6cc26b&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "shield-lock"
  },
  {
    title: "Mobile App Capture",
    content: "Overview of QM's key differentiators for mobile app, including our patented approach to capture, enterprise security, and optimal performance.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=a7de55a1-7469-49f6-a4db-e59d137898a3&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "phone"
  },
  {
    title: "Opportunity Analysis",
    content: "Explanation of how business impact is calculated through Opportunities - Includes many-to-one flow.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=62d4547e-dac3-4af2-ae10-33a652df6f42&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "graph-up-arrow"
  },
  {
    title: "Remote Event Creation",
    content: "A look at how to create events and errors remotely from within the QM UI - eliminate developer lift and remove analytics tagging lagtime",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=91d6913b-2b19-408a-831f-aa181c394ea9&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "code-square"
  },
  {
    title: "Journey Analysis",
    content: "With QM Journeys, identify friction points, optimize user flows, and enhance overall user experience by addressing specific issues or bottlenecks throughout the user journey.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=b5f5b705-345b-4019-adce-89e1a5c943b5&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "signpost-split"
  },
  {
    title: "DevOps Overview",
    content: "Why choose QM DevOps? Competitive analysis & understanding of how the package can serve as a supplement to other performance tools",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=6a84d850-00e2-43eb-97b7-482a842e938a&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "gear"
  },
  {
    title: "Quantum Activate",
    content: "Interactive walkthrough of how to use Activate to trigger an action (in this case, chat popup) upon user rage click. Includes one-to-many flow.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=31f4def5-4159-47a3-8da1-99fb21fe1b25&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "lightning"
  },
  {
    title: "GA4 DataLayer Integration",
    content: "Demonstrates how QM's GA4 integration, combining datalayer events with QM's robust technical and behavioral dataset, empowers you to supercharge your analysis and center around a single source of truth.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=962e8e5d-7b69-410e-ae7b-fe1a62cb013e&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "database"
  },
  {
    title: "API Capture & Network Details in Replay",
    content: "Precision Capture: Quantum's unique method captures every detail of a web session, down to the network API and resource performance. Shows how users with access to the private key can view API Request and Response bodies to diagnose issues.",
    externalLink: "https://demohq.quantummetric.com/player/?demoId=4903c236-4f32-4f6a-a2e4-9199e19288d1&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app",
    icon: "hdd-network"
  }
];


const WalnutDemoLibrary = () => (
  <Container>
    <Col className="d-flex justify-content-center align-items-center">
      <img
        className="mt-4"
        src="walnut-app-capture.png"
        alt="walnut hero"
        style={{ width: "46.1%", height: "auto", opacity: "86%" }}
      />
      <img
        className="mt-4"
        src="walnut.png"
        alt="walnut hero"
        style={{ width: "58%", height: "auto", opacity: "86%" }}
      />
      <img
        className="mt-4"
        src="walnut-opp.png"
        alt="walnut hero"
        style={{ width: "44.24%", height: "auto", opacity: "86%" }}
      />
    </Col>
    <Row className="mt-4">
      {demoContent.map((demo, index) => (
        <Col md={6} lg={4} key={index}>
          <div className="industry-card p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h2 className="industry-title mb-0">{demo.title}</h2>
              <i className={`bi bi-${demo.icon} industry-icon fs-2`}></i>
            </div>
            <a
              href={demo.externalLink}
              className="page-link d-block text-decoration-none text-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              <small className="text-muted">{demo.content}</small>
            </a>
          </div>
        </Col>
      ))}
    </Row>
  </Container>
);

export default WalnutDemoLibrary;
